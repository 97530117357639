body {
    margin: 0;
}


body > div > section .inside {
    max-width: 1000px;
    margin: 0 auto;
}

body > div.container-fluid {
    padding: 0;
}

.navbar-transparent {
    background-color: transparent;
    border: none;
}

.navbar-brand img {
    max-width: 200px;
}

.navbar-right {
    margin-top: 30px;
}

.navbar-right a {
    color: white;
}

.sectionOne {
    background: transparent url('../img/map-insight-header.jpg') no-repeat center;
    background-size: cover;
    padding-top: 40px;
}

.sectionOne h1 {
    padding-top: 150px;
    text-transform: uppercase;
    color: white;
    font-weight: 300;
    text-shadow: 0 0 4px rgba(0,0,0,1);
}

.sectionOne h1 span {
    color: #39b54a;
    font-weight: 700;
}

.sectionLogin button,
.sectionOne a {
    margin-top: 20px;
    margin-bottom: 200px;
    background: rgb(55,180,74);
    background: -moz-linear-gradient(top,  rgba(55,180,74,1) 0%, rgba(2,147,69,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(55,180,74,1) 0%,rgba(2,147,69,1) 100%);
    background: linear-gradient(to bottom,  rgba(55,180,74,1) 0%,rgba(2,147,69,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#37b44a', endColorstr='#029345',GradientType=0 );
    padding: 10px 20px;
    text-transform: uppercase;
    color: white;
    box-shadow: 0 0 2px rgba(0,0,0,1);
    display: inline-block;
}

.sectionOne.sectionLogin .row {
    margin: 0;
}

.sectionOne.sectionLogin h1 {
    padding-top: 0px;
}

.sectionLogin img {
    width: 300px;
    margin-top: 100px;
}

.sectionLogin .basicForm {
    margin-bottom: 100px;
    background: white;
    padding: 40px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.sectionLogin button {
    border-radius: 0;
    margin-bottom: 0;
}

.sectionLogin a {
    margin-bottom: 0;
    background: none;
    color: gray;
    box-shadow: none;
    padding: 0;
    text-transform: capitalize;
}

.mi-rememberme-msg {
    display: none;
}

.sectionTwo {
    padding: 70px 0;
    background: rgb(55,180,74);
    background: -moz-linear-gradient(top,  rgba(55,180,74,1) 0%, rgba(2,147,69,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(55,180,74,1) 0%,rgba(2,147,69,1) 100%);
    background: linear-gradient(to bottom,  rgba(55,180,74,1) 0%,rgba(2,147,69,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#37b44a', endColorstr='#029345',GradientType=0 );
}

.sectionTwo .info-block {
    color: white;
    position: relative;
    margin-top: 120px;
}

footer {
    padding: 100px 0;
    color: white;
    background: rgb(59,58,58);
    background: -moz-linear-gradient(top,  rgba(59,58,58,1) 0%, rgba(39,33,35,1) 100%);
    background: -webkit-linear-gradient(top,  rgba(59,58,58,1) 0%,rgba(39,33,35,1) 100%);
    background: linear-gradient(to bottom,  rgba(59,58,58,1) 0%,rgba(39,33,35,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3a3a', endColorstr='#272123',GradientType=0 );
    text-align: center;
}

footer img {
    display: block;
    margin: 0 auto;
    width: 400px;
}

.signup-login-question {
    display: block;
    margin-top: -10px;
    margin-bottom: 20px;
}

.signup-login-question a {
    margin-top: 0;
    text-transform: none !important;
    text-decoration: underline;
}